import React, { useState, type ReactElement } from "react";

interface TabsProps {
  children: ReactElement<TabItemProps>[];
  defaultIndex?: number;
}

const Tabs: React.FC<TabsProps> & { Item: typeof TabItem } = ({ defaultIndex, children }) => {
  const [activeTab, setActiveTab] = useState<number>(defaultIndex ?? 0);

  return (
    <div className="w-full">
      <div className="flex">
        {React.Children.map(children, (child, index) => {
          return (
            <Title
              title={child.props.title}
              isActive={activeTab === index}
              onClick={() => {
                setActiveTab(index);
                if (child.props.onClick) child.props.onClick();
              }}
            />
          );
        })}
      </div>
      {React.Children.map(children, (child, index) => {
        if (activeTab === index) return <div className="flex flex-col gap-2 h-full">{child.props.children}</div>;
      })}
    </div>
  );
};

interface TitleProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const Title = ({ title, isActive, onClick }: TitleProps) => {
  return (
    <div className={`hover:cursor-pointer ${isActive ? "border-b" : ""} w-full pb-2.5`} onClick={onClick}>
      <p className={`text-center text-sm ${isActive ? "text-white" : "text-chicago-200"}`}>{title}</p>
    </div>
  );
};

interface TabItemProps {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const TabItem: React.FC<TabItemProps> = ({ children }) => {
  return <>{children}</>;
};

Tabs.Item = TabItem;

export default Tabs;
