import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { TonIcon } from "@/components/Icons";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";

const RANK1 = [
  {
    rank: "1st",
    prize: 300,
  },
  {
    rank: "2nd",
    prize: 200,
  },
  {
    rank: "3rd",
    prize: 100,
  },
];

const RANK2 = [
  {
    rank: "4th - 5th",
    prize: 50,
  },
  {
    rank: "6th - 50th",
    prize: 30,
  },
  {
    rank: "51th - 150th",
    prize: 10,
  },
];

export const Prize = () => {
  return (
    <div className="flex flex-col h-full py-4">
      <div className="flex justify-end mb-8">
        <TonUsdPill />
      </div>

      <Tabs>
        <Tabs.Item title="33 Thumba">
          <PrizeContent />
        </Tabs.Item>
        <Tabs.Item title="10 Thumba">
          <PrizeContent />
        </Tabs.Item>
        <Tabs.Item title="Free">
          <PrizeContent />
        </Tabs.Item>
      </Tabs>

      <div className="flex flex-col gap-6 mt-6">
        <div className="flex flex-col w-full justify-center items-center text-white">
          <p className="text-xs">Total Entry fee</p>
          <p className="text-xl">
            16.5<span className="textsm">TON</span>
          </p>
        </div>
        <PrimaryButton text="ENTER" onClick={() => {}} />
      </div>
    </div>
  );
};

const PrizeContent = () => {
  return (
    <div>
      <div className="flex justify-between py-4">
        <p className="textsm text-white">Prize Payouts</p>
        <div className="flex gap-1">
          <TonIcon />
          <p className="text-white tex-xl">100,000</p>
        </div>
      </div>
      {/* table */}
      <div className="flex flex-col p-4 bg-button-background rounded-md">
        <div className="flex flex-col gap-3">
          {RANK1.map((data) => (
            <RankRow className="text-white text-sm" {...data} key={data.rank} />
          ))}
        </div>
        <div className="w-full border border-solid my-4" />
        <div className="flex flex-col gap-3">
          {RANK2.map((data) => (
            <RankRow className="text-icon text-xs" {...data} key={data.rank} />
          ))}
        </div>
      </div>
    </div>
  );
};

const RankRow = ({ rank, prize, className }: { rank: string; prize: number; className?: string }) => {
  return (
    <div className={`flex justify-between ${className}`}>
      <p>{rank}</p>
      <div className="flex gap-1 justify-center items-center">
        <TonIcon />
        <p>{prize}</p>
      </div>
    </div>
  );
};
