import { BackButton } from "@/components/Buttons/BackButton";
import { Container } from "@/components/Container";
import { Deadline } from "@/components/Deadline";
import Tabs from "@/components/Tabs";
import { useCountDown } from "@/hooks/useCountDown";
import type { EntryDetails } from "@/types/EntryDetails";
import type { RoundDetails } from "@/types/RoundDetails";
import { useState } from "react";
import type { RoundPageType } from "..";
import { RoundInfoBanner } from "../RoundInfo/RoundInfoBanner";
import { Entry } from "./Entry";
import { Prize } from "./Prize";
export interface EntryProps {
  round: RoundDetails;
  setEntryDetails: (body: EntryDetails) => void;
  entryDetails: EntryDetails;
  setCurrentPage: (page: RoundPageType) => void;
}

export const SelectEntry: React.FC<EntryProps> = (props) => {
  const { round, setCurrentPage } = props;

  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);

  if (!round) return null;

  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));

  return (
    <Container className="flex flex-col">
      <div className="flex mb-2.5">
        <BackButton onClick={() => setCurrentPage("select-captain")} />
        <RoundInfoBanner
          round={round}
          isRoundInfoTabsOpen={isRoundInfoTabsOpen}
          onCloseRoundInfoTabs={() => setIsRoundInfoTabsOpen(false)}
          onOpenRoundInfoTabs={() => setIsRoundInfoTabsOpen(true)}
        />
      </div>
      <Deadline deadlineTime={deadlineTime} />

      <Tabs>
        <Tabs.Item title="Entry">
          <Entry {...props} />
        </Tabs.Item>
        <Tabs.Item title="Prizes">
          <Prize />
        </Tabs.Item>
      </Tabs>
    </Container>
  );
};
