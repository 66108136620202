import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { CustomModal } from "@/components/CustomModal";
import { TonIcon } from "@/components/Icons";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useCreateEntry } from "@/hooks/soccer/useCreateEntry";
import { useCreateEntryInvoice } from "@/hooks/soccer/useCreateEntryInvoice";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { EntryDetails } from "@/types/EntryDetails";
import { getErrorMessage } from "@/utils/error";
import { useInvoice } from "@telegram-apps/sdk-react";
import { fromNano } from "@ton/ton";
import { useCallback } from "react";

interface InsufficientModalProps {
  isOpen: boolean;
  onClose: () => void;
  totalFee: number;
  entryDetails: EntryDetails;
  onSuccess: () => void;
}

export const InsufficientModal = ({ isOpen, onClose, totalFee, entryDetails, onSuccess }: InsufficientModalProps) => {
  const invoice = useInvoice();
  const user = useUserContext();

  const { openModal } = useErrorModalContext();
  const handleEnterEntryError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error entering round", message);
    },
    [openModal],
  );
  const { trigger: createEntry } = useCreateEntry(handleEnterEntryError);
  const { trigger: createInvoice } = useCreateEntryInvoice(handleEnterEntryError);

  const onClickEntry = async () => {
    try {
      const res = await createEntry(entryDetails);
      const status = await createInvoice({
        entryTxId: res.entryTx.id,
        telegramUserId: user.telegramUserId,
        invoice,
      });

      if (status === "paid") {
        onClose();
        onSuccess();
      }
    } catch (error) {
      window.alert("Fail to purchase");
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col text-white text-center mt-5">
        <div className="flex flex-col text-white justify-center items-center text-center">
          <div className="flex gap-1">
            <p className="text-[22px]">Total Entry fee</p>
            <div className="flex items-center gap-1">
              <TonIcon />
              <p className="text-[22px]">{fromNano(totalFee)}</p>
            </div>
          </div>
        </div>
        <PrimaryButton className="w-full mt-[30px]" text="Buy" onClick={onClickEntry} />
      </div>
    </CustomModal>
  );
};
