import RomanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { Invoice, InvoiceStatus } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import useSWRMutation from "swr/mutation";

interface CreateInvoiceResponse {
  invoiceLink: string;
}

interface CreateInvoiceArgs {
  telegramUserId: number;
  entryTxId: string;
  invoice: Invoice;
}

interface CreateInvoiceRequest {
  telegramUserId: number;
  entryTxId: string;
}

const postCreateInvoiceRequest = async (
  url: string,
  { arg }: { arg: CreateInvoiceArgs },
): Promise<InvoiceStatus | undefined> => {
  const { invoice, entryTxId, telegramUserId } = arg;

  const response = await RomanApi.post<CreateInvoiceResponse, CreateInvoiceRequest>(url, {
    entryTxId,
    telegramUserId,
  });

  const invoiceLink = response.invoiceLink;

  if (invoiceLink && !invoice.isOpened) {
    const status: InvoiceStatus = await invoice.open(invoiceLink, "url");
    return status;
  }
};

export const useCreateEntryInvoice = (handleError: (err: RomanApiError) => void) => {
  const { trigger, isMutating, data, error } = useSWRMutation("/soccer/entry/create-invoice", postCreateInvoiceRequest);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    trigger,
    isMutating,
    data,
  };
};
