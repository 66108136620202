/**
 * Abbreviates a person's name given in the format "LastName, FirstName".
 * @param {string} fullName - The full name of the person.
 *   - If only one name is provided like in a nickname, it is treated as the last name.
 * @returns {string} - The abbreviated name in the format "F.LastName", or just "LastName" if no first name is provided.
 * @example abbreviateName("Doe, John"); // Returns "J.Doe"
 */
export const abbreviateName = (fullName: string) => {
  const parts = fullName.split(", ").map((part) => part.trim());
  if (parts.length === 1) {
    return parts[0];
  }
  const [lastName = "", firstName = ""] = parts;
  const abbreviatedFirstName = firstName ? `${firstName.charAt(0).toUpperCase()}.` : "";
  return `${abbreviatedFirstName} ${lastName}`;
};

/**
 * Formats a person's name given in the format "LastName, FirstName".
 * @param {string} fullName - The full name of the person.
 *   - If only one name is provided like in a nickname, it is treated as the last name.
 * @returns {string} - The abbreviated name in the format "F.LastName", or just "LastName" if no first name is provided.
 * @example formatName("Doe, John"); // Returns "John Doe"
 */
export const formatName = (fullName: string) => {
  const parts = fullName.split(", ").map((part) => part.trim());
  if (parts.length === 1) {
    return parts[0];
  }
  const [lastName = "", firstName = ""] = parts;
  return `${lastName} ${firstName}`;
};
